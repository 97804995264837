import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { history } from '../../store';
import { isAuthenticatedSelector, tokenSelector } from '../../store/auth/selectors';
import { APP_URLS, MAX_PAGINATION_PAGE_SIZE } from '../settings';
import {
  getProperties,
  getProperty,
  updatePropertiesIsLoading,
  getClosedProperties,
  resetProperties,
  getPendedAutoEnrol,
} from '../../store/properties/propertiesActions';
import { usePageLoaded } from '.';
import { resetApp } from '../../store/app/appActions';
import { propertiesErrorSelector } from '../../store/properties/propertiesSelectors';

const useFetchProperties = () => {
  const dispatch = useDispatch();
  const token = useSelector(tokenSelector);
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const propertiesError = useSelector(propertiesErrorSelector);
  const pageLoaded = usePageLoaded();

  const getPropertiesList = () => {
    dispatch(getPendedAutoEnrol(token, history, (response)  => {
      const { hasPendedAutoEnrol } = response;
      dispatch(getProperties(1, MAX_PAGINATION_PAGE_SIZE, token, history, (response) => {
        const { properties, accountDisabled } = response;

        if (accountDisabled) {
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.ACCOUNT_DISABLED);
          return;
        }

        if (propertiesError || !properties) {
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.ERROR);
          return;
        }

        if (properties && properties.length === 1) {
          const currentProperty = properties[0];
          if (currentProperty.accountReference) {
            dispatch(
              getProperty(encodeURIComponent(currentProperty.accountReference), token, history, () => {
                dispatch(updatePropertiesIsLoading(false));
                history.push(APP_URLS.ROOT);
                return;
              }),
            );
            dispatch(updatePropertiesIsLoading(false));
            history.push(APP_URLS.ERROR);
            return;
          }
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.ERROR);
          return;
        }

        if (properties && properties.length > 1) {
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.PROPERTIES);
          return;
        }

        if (properties.length === 0 && hasPendedAutoEnrol) {
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.ACCOUNT_SETUP_IN_PROGRESS);
          return;
        }

        if (properties.length === 0 && !hasPendedAutoEnrol) {
          dispatch(updatePropertiesIsLoading(false));
          history.push(APP_URLS.ADD_PROPERTY);
          return;
        }

        dispatch(updatePropertiesIsLoading(false));
        history.push(APP_URLS.ERROR);
      }));
    }));


  }

  useEffect(() => {
    if (!pageLoaded && isAuthenticated && token) {
      // setPageLoaded(true);
      dispatch(resetProperties());
      dispatch(resetApp());

      // If accountReference in URL save it to session storage to get property details with it.
      const savedAccountReferenceFromURL = window.sessionStorage.getItem('accountReferenceFromUrl') || null;

      if (savedAccountReferenceFromURL) {
        dispatch(
          getProperty(
            encodeURIComponent(savedAccountReferenceFromURL),
            token,
            history,
            () => {
              window.sessionStorage.removeItem('accountReferenceFromUrl');
              dispatch(updatePropertiesIsLoading(false));
              history.push(APP_URLS.ROOT);
            },
            () => {
              getPropertiesList();
            },
          ),
        );
      } else {
        getPropertiesList();
      }
      dispatch(getClosedProperties(1, MAX_PAGINATION_PAGE_SIZE, token, history));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, pageLoaded, token, getPropertiesList]);

  return pageLoaded;
};

export default useFetchProperties;
