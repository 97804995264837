import { SOMETHING_WENT_WRONG_ACTION } from '../../common/constants/somethingWentWrongAction';
import { handleErrorRedirect } from '../../common/functions/helpers';
import { updateLoadingMessage } from '../app/appActions';
import { fetchProperties, fetchProperty, fetchPendedProperties, switchToPaperless } from './propertiesService';

export const PROPERTIES_ACTIONS = {
  UPDATE_LOADING: 'PROPERTIES_ACTIONS.UPDATE_LOADING',
  UPDATE_PROPERTIES_LIST: 'PROPERTIES_ACTIONS.UPDATE_PROPERTIES_LIST',
  UPDATE_CLOSED_PROPERTIES_LIST: 'PROPERTIES_ACTIONS.UPDATE_CLOSED_PROPERTIES_LIST',
  UPDATE_SELECTED_PROPERTY: 'PROPERTIES_ACTIONS.UPDATE_SELECTED_PROPERTY',
  UPDATE_ERROR: 'PROPERTIES_ACTIONS.UPDATE_ERROR',
  RESET_PROPERTIES: 'RESET_PROPERTIES',
  UPDATE_BILL_PREFERENCE: 'UPDATE_BILL_PREFERENCE',
  PENDED_PROPERTIES: 'PENDED_PROPERTIES',
};

export const updatePropertiesIsLoading = (state) => ({
  type: PROPERTIES_ACTIONS.UPDATE_LOADING,
  payload: state,
});

export const updatePropertiesList = (response) => ({
  type: PROPERTIES_ACTIONS.UPDATE_PROPERTIES_LIST,
  payload: response,
});

export const updateClosedPropertiesList = (response) => ({
  type: PROPERTIES_ACTIONS.UPDATE_CLOSED_PROPERTIES_LIST,
  payload: response,
});

export const updateSelectedProperty = (response) => ({
  type: PROPERTIES_ACTIONS.UPDATE_SELECTED_PROPERTY,
  payload: response,
});

export const updatePendedProperties = (response) => ({
  type: PROPERTIES_ACTIONS.PENDED_PROPERTIES,
  payload: response,
});

export const updatePropertiesError = (error) => ({
  type: PROPERTIES_ACTIONS.UPDATE_ERROR,
  payload: error,
});

export const resetProperties = () => ({
  type: PROPERTIES_ACTIONS.RESET_PROPERTIES,
});

export const updateBillPreference = (isPaperless) => ({
  type: PROPERTIES_ACTIONS.UPDATE_BILL_PREFERENCE,
  payload: isPaperless,
});

export const getProperties = (page, pageSize, token, history, responseCallback = null, errorCallback = null) => async (
  dispatch,
) => {
  const successHandler = (response) => {
    if (response && response.data) {
      dispatch(updatePropertiesList(response.data));
      if (responseCallback) {
        responseCallback(response.data);
      } else {
        dispatch(updatePropertiesIsLoading(false));
      }
    }
  };

  const errorHandler = (error) => {
    dispatch(updatePropertiesIsLoading(false));
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.GET_PROPERTY, history, () => {
      dispatch(updatePropertiesError(error));
      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

  dispatch(updatePropertiesIsLoading(true));
  dispatch(updateLoadingMessage('Loading properties'));

  try {
    const response = await fetchProperties(page, pageSize, token);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  }
};

export const getClosedProperties = (page, pageSize, token, history, responseCallback) => async (dispatch) => {
  const successHandler = (response) => {
    if (response && response.data) {
      dispatch(updateClosedPropertiesList(response.data));
      if (responseCallback) {
        responseCallback();
      }
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.GET_PROPERTY, history, () => {
      dispatch(updatePropertiesError(error));
    });
  };

  try {
    const response = await fetchProperties(page, pageSize, token, true);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
    dispatch(updatePropertiesIsLoading(false));
  }
};

export const getProperty = (accountReference, token, history, responseCallback = null, errorCallback = null) => async (
  dispatch,
) => {
  const successHandler = (response) => {
    if (response && response.data) {
      dispatch(updateSelectedProperty(response.data));
      if (responseCallback) {
        responseCallback();
      } else {
        dispatch(updatePropertiesIsLoading(false));
      }
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.GET_PROPERTY, history, () => {
      dispatch(updatePropertiesError(error));
      errorCallback(error);
    });
  };

  dispatch(updatePropertiesIsLoading(true));
  dispatch(updateLoadingMessage('Loading property details'));

  try {
    const response = await fetchProperty(accountReference.replace(/ /g, ''), token);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updatePropertiesIsLoading(false));
    dispatch(updateLoadingMessage(''));
  }
};

export const switchToPaperlessBilling = (
  accountReference,
  isPaperless,
  token,
  history,
  responseCallback,
  errorCallback,
) => async (dispatch) => {
  const successHandler = (response) => {
    if (response && response.status === 200) {
      dispatch(updateBillPreference(isPaperless));
      responseCallback(response);
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.SWITCH_TO_PAPERLESS, history, () => {
      dispatch(updatePropertiesError(error));
      errorCallback();
    });
  };

  dispatch(updatePropertiesIsLoading(true));
  dispatch(updateLoadingMessage('Saving billing preference'));

  try {
    const params = {
      isPaperless,
      accountReference: accountReference.replace(/ /g, ''),
    };
    const response = await switchToPaperless(params, token);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  } finally {
    dispatch(updatePropertiesIsLoading(false));
    dispatch(updateLoadingMessage(''));
  }
};

export const getPendedAutoEnrol = (token, history, responseCallback = null, errorCallback = null) => async (dispatch) => {
  const successHandler = (response) => {
    if (response && response.data) {
      dispatch(updatePendedProperties(response.data));
      if (responseCallback) {
        responseCallback(response.data);
      } else {
        dispatch(updatePropertiesIsLoading(false));
      }
    }
  };

  const errorHandler = (error) => {
    handleErrorRedirect(error, SOMETHING_WENT_WRONG_ACTION.GET_PROPERTY, history, () => {
      dispatch(updatePropertiesError(error));
      if (errorCallback) {
        errorCallback(error);
      }
    });
  };

    dispatch(updatePropertiesIsLoading(true));
    dispatch(updateLoadingMessage('Loading properties'));

  try {
    const response = await fetchPendedProperties(token);
    response ? successHandler(response) : errorHandler(response.status);
  } catch (error) {
    error.status ? errorHandler(error.status) : errorHandler(500);
  }
};
