import React, { useState, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import { Icon } from 'yw-ui.v2';
import { useSelector } from 'react-redux';
import { APP_URLS, YW_URLS } from '../../../common/settings';
import { Button } from 'yw-ui.v2/dist/components';
import {
  closedPropertiesListSelector,
  propertiesListSelector,
  selectedPropertySelector,
} from '../../../store/properties/propertiesSelectors';
import { getMenuItems } from './constants/menuItems';
import { useAppAuth, useOnClickOutside } from '../../../common/hooks';

const NavBarItems = () => {
  const navMenuRef = useRef();
  const doLogOut = useAppAuth();
  const selectedProperty = useSelector(selectedPropertySelector);
  const { properties, accountDisabled } = useSelector(propertiesListSelector);
  const { properties: closedProperties } = useSelector(closedPropertiesListSelector);
  const [menuOpen, setMenuOpen] = useState(false);

  useOnClickOutside(navMenuRef, () => closeMenu());

  const closeMenu = () => {
    if (document && document.getElementById('root')) {
      document.getElementById('root').classList.remove('menu-open');
    }
    setMenuOpen(false);
  };

  const toggleMenu = () => {
    if (document && document.getElementById('root')) {
      if (!menuOpen) {
        document.getElementById('root').classList.add('menu-open');
      } else {
        document.getElementById('root').classList.remove('menu-open');
      }
    }
    setMenuOpen(!menuOpen);
  };

  const handleMenuToggleKeyDown = (evt) => {
    if (evt.keyCode === 32 || evt.keyCode === 13) {
      toggleMenu();
      evt.preventDefault();
    }
  };

  const handleKeyDown = (evt) => {
    if (evt.keyCode === 32 || evt.keyCode === 13) {
      onLogout();
      evt.preventDefault();
    }
  };

  const onLogout = () => {
    doLogOut();
  };

  return (
    <div id="header-navbar">
      <div className={`nav-top-menu ${accountDisabled ? 'account-disabled' : ''}`}>
        {!accountDisabled && (
          <NavLink to={APP_URLS.MY_PROFILE} id="my-profile_link" qa="my-profile_link">
            My profile&nbsp;&nbsp;
            <Icon qa="account-settings-icon-account" colour="ywDarkBlue" size="250" icon="IconAccountFilled" />
          </NavLink>
        )}
        <div id="logout_link" onClick={onLogout} onKeyDown={handleKeyDown} role="button" tabIndex={0}>
          Log out&nbsp;&nbsp;
          <Icon qa="account-settings-icon-logout" colour="ywDarkBlue" size="250" icon="IconLogoutFilled" />
        </div>
      </div>
      <div className="nav-bottom-menu">
        <div className="right">
          {selectedProperty.accountReference && (
            <NavLink to="/" id="home_link" qa="home_link" className="home-link">
              Home
            </NavLink>
          )}
          <div className="menu-outer-wrapper" ref={navMenuRef}>
            <div
              id="menu_link_toggle"
              className={`menu-link-wrapper ${!selectedProperty.accountReference ? 'no-active-property' : ''}`}
              onClick={toggleMenu}
              onKeyDown={handleMenuToggleKeyDown}
              role="button"
              tabIndex={0}
            >
              Menu&nbsp;&nbsp;
              <Icon
                qa="account-settings-icon-chevron"
                className={`menu-chevron ${menuOpen ? 'inverse' : ''}`}
                colour="ywDarkBlue"
                size="150"
                icon="IconChevron"
                direction="down"
              />
              <div className={`bugger-menu ${menuOpen ? 'is-open' : ''}`} />
            </div>
            {menuOpen && (
              <div id="dropdown_menu_content" className="dropdown-menu-content">
                <div className="menu-link-border" />
                {selectedProperty.accountReference && (
                  <h2 className="u-mt--200 u-ml--200 u-display--hidden-md">Your property</h2>
                )}
                <ul>
                  {selectedProperty.accountReference && (
                    <li className="u-display--hidden-md">
                      <NavLink id="mobile_home_link" qa="mobile_home_link" to={APP_URLS.ROOT} onClick={closeMenu}>
                        Home
                      </NavLink>
                    </li>
                  )}
                  {getMenuItems(selectedProperty, properties, closedProperties)
                    .filter((item) => item.isVisible)
                    .map((item) =>
                      item.isExternalUrl ? (
                        <li key={item.id}>
                          <a href={item.url} id={item.id} target="_blank" rel="noopener noreferrer" onClick={closeMenu}>
                            {item.title}
                          </a>
                        </li>
                      ) : (
                        <li key={item.id}>
                          <NavLink to={item.url} id={item.id} onClick={closeMenu}>
                            {item.title}
                          </NavLink>
                        </li>
                      ),
                    )}
                </ul>
                <h2 className="u-mt--200 u-ml--200 u-display--hidden-md">Your account</h2>
                <ul className="u-mb--200 u-display--hidden-md">
                  <li className="u-display--hidden-md">
                    <NavLink to={APP_URLS.MY_PROFILE} onClick={closeMenu}>
                      My profile
                    </NavLink>
                  </li>
                  <li className="u-display--hidden-md">
                    <Button
                      qa="logout-button-mobile"
                      state="link"
                      onClick={onLogout}
                      onKeyDown={handleKeyDown}
                      tabIndex={0}
                    >
                      Log out
                    </Button>
                  </li>
                </ul>
                <a
                  href={YW_URLS.CONTACT_US}
                  className="c-button c-button--success no-underline get-in-touch-mobile"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Get in touch
                </a>
              </div>
            )}
          </div>
        </div>
        <a
          href={YW_URLS.CONTACT_US}
          className="c-button c-button--success no-underline get-in-touch"
          target="_blank"
          rel="noopener noreferrer"
        >
          Get in touch
        </a>
      </div>
    </div>
  );
};

export default NavBarItems;
