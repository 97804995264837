import React from 'react';
import PropTypes from 'prop-types';

import { ButtonRow, Button } from 'yw-ui.v2';

const ButtonControls = ({ nextLabel, backLabel, onNext, onBack, renderNext, renderBack }) => (
  <ButtonRow className="u-mt--200 u-mb--200">
    {renderBack && <Button label={backLabel} onClick={onBack} id="back-button" qa="back-button" />}
    {renderNext && (
      <Button type="submit" label={nextLabel} state="success" onClick={onNext} id="next-button" qa="next-button" />
    )}
  </ButtonRow>
);

ButtonControls.defaultProps = {
  backLabel: 'Back',
  nextLabel: 'Next step',
  renderBack: true,
  renderNext: true,
};

ButtonControls.propTypes = {
  backLabel: PropTypes.string.isRequired,
  nextLabel: PropTypes.string.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  renderBack: PropTypes.bool.isRequired,
  renderNext: PropTypes.bool.isRequired,
};

export default ButtonControls;
